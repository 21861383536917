.App {
  text-align: center;
}
.req_height {
  height: 38vh;
  object-fit: contain;
}

.cap {
  text-transform: capitalize;
}

.del_hov:hover {
  background-color: gray;
}

.react-tel-input .form-control {
  width: 100% !important;
}
.w_20 {
  width: 20rem;
}
.del_hov2:hover {
  background-color: white;
}

.del_hov:hover > i {
  color: red;
}

.btn_del {
  border: none;
  border-radius: 0px;
}
.admin_list_pop_eye {
  position: absolute;
  top: 7.9rem;
  right: 2rem;
  font-size: 1.2rem;
}
.w_20 {
  width: 20rem !important;
}

.form-control::placeholder {
  color: grey !important;
}

.form-control {
  color: black !important;
}

.upp {
  text-transform: uppercase;
}

.d-flex-spa {
  display: flex;
  justify-content: space-between;
}

input::-webkit-input-placeholder {
  color: black !important;
  opacity: 0.3;
}

i {
  cursor: pointer;
}
.fa-refresh {
  cursor: pointer;
}

.grid_end {
  display: grid;
  justify-content: end;
}

.grid_center {
  display: grid;
  justify-content: center;
}

.dflex_center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dflex_space_arounf {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

:is(.mis_reports) :is(th) {
  padding: 10px 0px 0px 0px;
}

:is(.mis_reports) :is(tr) {
  font-size: 1rem;
  border: none;
  justify-content: center;
  display: flex;
}

.mis_body_td {
  justify-content: space-around;
  display: flex;
}

:is(.mis_body_td) :is(td) {
  border: none;
}

.active_button {
  background: #4ca1af !important;
}

.pop_phone {
  color: gold;
  font-size: 1.3rem;
}
thead {
  /* background: linear-gradient(
    to right,
    #4072a1,
    #5a9be5,
    #344966,
    #4072a1,
    #5a9be5,
    #344966
  ) !important; */
  background: linear-gradient(to right, #1e3c72, #2a5298);
}

.userid_title {
  color: gold;
  font-size: 1.3rem;
}

.bb {
  font-weight: bold;
}

.history_button {
  color: white;
  font-weight: bold;
  background-color: #4f89c5 !important;
}

.d-flex-spa {
  display: flex;
  justify-content: space-between;
  margin-left: -2rem;
  margin-top: 1rem;
  font-size: 15px;
}

.user_card > li {
  list-style-type: none;
}

.user_card > h2 {
  display: flex;
  justify-content: center;
}

.dflex_start {
  display: flex;
  justify-content: start;
  align-items: center;
}

.dflex_end {
  display: flex;
  justify-content: end;
  align-items: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.edit_chan_input {
  width: 16rem;
  height: 2.3rem;
}

.eye_icon {
  position: absolute;
  top: 2.1rem;
  right: 1.4rem;
  font-size: 1.2rem;
}
small {
  color: red;
  font-size: 0.8rem;
}

.cursor_pointer {
  cursor: pointer;
}

.custom_alert {
  padding: 0.4375rem 1.25rem !important;
}

.login-c_opt_height {
  height: 374px !important;
}

.opt_input {
  justify-content: space-around;
}

.table_border {
  border: 2px solid white;
}

.opt_input_box {
  width: 2.5rem !important;
  height: 3rem !important;
}

.admin_dropdown {
  padding-left: 23rem;
}

.pro_card_rmbg {
  background-color: transparent !important;
  border: none;
}

@media only screen and (max-width: 922px) {
  .admin_dropdown {
    padding-left: 0px;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.react-tabs__tab-list {
  border-bottom: 1px solid transparent;
  margin: 0 0 10px;
  padding: 0;
  width: 80% !important;
  margin: auto !important;
}
.react-tabs__tab-panel--selected {
  display: block;
  width: 80% !important;
  margin: auto !important;
  border: 1px solid transparent;
}
.react-tabs__tab {
  display: inline-block;
  border: 1px solid white !important;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
  width: 15% !important;
  text-align: center !important;
  font-size: large !important;
  background: #be5869 !important;
  color: white;
}
.react-tabs__tab--selected {
  background: #4072a1 !important;
  border-color: #aaa;
  color: white !important;
  border-radius: 5px 5px 0 0;
  width: 15% !important;
  text-align: center !important;
}
